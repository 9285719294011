import React, { useState } from 'react';
import './firstlogin.css';
import Swal from 'sweetalert2'
import validator from 'validator';
import ojo from '../Imagenes/ojo.png'

function Nuevousuario () {
const username = localStorage.getItem('username'); // función que obtiene el username

const [formData, setFormData] = useState({
    username: username,
    currentPassword: '',
    newPassword: ''
    })

const handleChange = (e) => {
    const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

const [formData2, setFormData2] = useState({
  newPassword2: '',
  })

const handleChange2 = (e) => {
  const { name, value } = e.target;
      setFormData2({
      ...formData2,
      [name]: value
      });
  };
    
const handleSubmit = async (e) => {
try {
    const response = await fetch('http://mine-360.com:8080/api/auth/updPass', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(formData)
    });

    if (response.ok) {
    Swal.fire({
        title: "Su nueva contraseña ha sido guardada, por favor inicie sesion nuevamente!",
        confirmButtonText: '<a href="./">OK</a>',
        icon: "success",
    })
    } else {
    Swal.fire({
        title: "Error al cambiar contraseña!",
        confirmButtonText: 'Atras',
        icon: "error",
    })
    }
} catch (error) {
    console.error('Error al cambiar contraseña!', error);
    Swal.fire({
    title: "Error al cambiar contraseña!",
    confirmButtonText: 'Atras',
    icon: "error",
    })
}
};

const enviar = (e) => {
    const passwordspecial = /[$#%&/*_@]/;
  e.preventDefault();

  if (formData.newPassword.length < 6 && formData.newPassword.length > 1) {
      Swal.fire({
          title: "La contraseña debe tener al menos 6 caracteres",
          confirmButtonText: 'OK',
          icon: "warning",
      });
  } else if (formData.newPassword === '' || formData.currentPassword === '') {
      Swal.fire({
          title: "Rellene los campos faltantes!",
          confirmButtonText: 'OK',
          icon: "warning",
      });
  } else {
      if (validator.isAlpha(formData.newPassword, 'es-ES') === true || passwordspecial.test(formData.newPassword) === false) {
          Swal.fire({
              title: "La contraseña debe contener al menos 1 número y al menos 1 caracter especial .$#%&/*",
              confirmButtonText: 'OK',
              icon: "warning",
          });
      } else {
          if (formData.newPassword !== formData2.newPassword2) {
              Swal.fire({
                  title: "Las contraseñas nuevas no coinciden",
                  confirmButtonText: 'OK',
                  icon: "warning",
              });
          } else {
              if (formData.currentPassword === formData.newPassword) {
                  Swal.fire({
                      title: "La contraseña nueva no puede ser igual a la anterior",
                      confirmButtonText: 'OK',
                      icon: "warning",
                  });
              } else {
                  Swal.fire({
                      title: "¿Quieres guardar los cambios?",
                      showCancelButton: true,
                      confirmButtonText: "Guardar",
                      icon: "question",
                  })
                      .then((result) => {
                          if (result.isConfirmed) {
                              handleSubmit();
                          }
                      })
                      .catch(error => {
                          if (error.status === 401) {
                              Swal.fire({
                                  title: "Su contraseña antigua no coincide",
                                  confirmButtonText: 'OK',
                                  icon: "error",
                              });
                          }
                      });
              }
          }
      }
  }
}

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [isPasswordVisible3, setIsPasswordVisible3] = useState(false);
  
  const handleClick = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  
  const handleClick2 = () => {
    setIsPasswordVisible2(!isPasswordVisible2);
  };
  
  const handleClick3 = () => {
    setIsPasswordVisible3(!isPasswordVisible3);
  };

  return (

    <div className="primerloginbody">
    
      <div className="primerlogin">
    
        <div className="primerlogin1-1">

          <div style={{ fontWeight:"bold", textAlign: "center", fontSize: "1.5vh"}}>POR SEGURIDAD DEBE CAMBIAR SU CONTRASEÑA AHORA.</div>

              <div className="primerloginordenar">
                    <label for="ayudante 2">INTRODUZCA SU ANTIGUA CONTRASEÑA:</label>
                    <div><input  id="primerloginestilo" type={isPasswordVisible ? 'text' : 'password'} maxlength="20" name="currentPassword" onChange={handleChange}></input><img src={ojo} alt="Ojo" onClick={handleClick} className='ojo'/></div>
              </div>

              <div className="primerloginordenar" >
                  <label>INTRODUZCA UNA NUEVA CONTRASEÑA:</label>
                  <div><input  id="primerloginestilo" type={isPasswordVisible2 ? 'text' : 'password'} maxlength="20" name="newPassword" onChange={handleChange}></input><img src={ojo} alt="Ojo" onClick={handleClick2} className='ojo'/></div>
              </div>

              <div className="primerloginordenar" >
                  <label for="ayudante 1">VERIFIQUE SU NUEVA CONTRASEÑA:</label>
                  <div><input id="primerloginestilo" type={isPasswordVisible3 ? 'text' : 'password'} maxlength="20" name="newPassword2" onChange={handleChange2}></input><img src={ojo} alt="Ojo" onClick={handleClick3} className='ojo'/></div>
                  
              </div>

        </div>

        <div className="primerlogin1-2">

          <button type="button" className="botonprimerlogin" onClick={enviar} >Enviar </button>

        </div>
      
      </div>

    </div>
  );
}

export default Nuevousuario;