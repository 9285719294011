import React, { useState, useEffect, useRef } from 'react'
import '../listado.css'
import '../formulario.css'
import moment from 'moment'
import descargar from '../Imagenes/descargar.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calendar from '../Imagenes/calendar.png'

const SearchComponent = () => {
  const [search, setSearch] = useState("")

  const URL = 'http://www.mine-360.com:8080/documentos'

  const [users, setUsers] = useState([])
  
  const showData = async () => {
    const response = await fetch(URL)
    const data = await response.json()
    setUsers(data)
  }
  
  useEffect(() => {
    showData()
  
    const interval = setInterval(() => {
      showData()
    }, 10000)
  
    return () => clearInterval(interval)
  }, [])

  const searcher = (e) => {
      setSearch(e.target.value)   
  }

  const results = !search ? users : users.filter((dato) => {
    return Object.values(dato).some(value =>
        value.toString().toLowerCase().includes(search.toLowerCase())
    )
  })


  const fechaFormateada = (fecha) => {
    return moment(fecha).format('YYYY-MM-DD HH:mm');
  };

  const handleDownload = (fileName) => {
    const downloadUrl = `https://www.mine-360.com/documentos/${fileName}`;
  
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.setAttribute('download', fileName);
    downloadLink.setAttribute('target', '_blank'); // Abrir en una nueva pestaña

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSearch(date ? date.toISOString().slice(0, 7) : ''); // Formatea la fecha en formato yyyy-MM
  }

  const datepickerRef = useRef();

  const sortedResults = results.sort((b, a) => new Date(a.ultimaActualizacion) - new Date(b.ultimaActualizacion));

  return (
    <div className='container'>
      
      <div style={{ display: "flex", position: "relative"}}>
      <input id='buscarequipos' value={search} onChange={searcher} type="text" placeholder='Buscar' className='form-control' style={{ width: "80%", marginRight: "2%"}}/>
      <DatePicker 
      ref={datepickerRef} 
      className='form-control' 
      style={{ width: "20%", cursor: "pointer"}} // Agregar cursor pointer aquí
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM"
      showMonthYearPicker
      placeholderText="Seleccione Fecha"
    />
      <img src={calendar} alt="Calendario" onClick={() => datepickerRef.current.setOpen(true)} style={{ width: "40px", right: "0px", position: "absolute", cursor: "pointer"}} /> 
      </div>
      <table id='tablaequipos' className='table table-striped table-hover mt-1 shadow-lg'>
        <thead>
          <tr>
            <th>ARCHIVO</th>
            <th>ULTIMA ACTUALIZACION</th>
            <th>DESCARGAR</th>
          </tr>
        </thead>
        <tbody>
          {sortedResults.map((user) => (
            <tr key={user.id}>
              <td style={{ textDecoration: "underline", cursor:"pointer"}} onClick={() => handleDownload(user.nombre)}>{user.nombre}</td>
              <td>{fechaFormateada(user.ultimaActualizacion)}</td>
              <td>
                <img src={descargar} style={{ height:"20px", width:"30px", cursor:"pointer"}} alt="Descargar" onClick={() => handleDownload(user.nombre)}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

//onClick={() => handleDownload(user.nombre)}

export default SearchComponent