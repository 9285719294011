import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const WeatherChart = ({ field }) => {
  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    fetch('http://www.mine-360.com:8080/api/app/get6weather')
      .then(response => response.json())
      .then(data => {
        const sortedData = data
          .map(entry => ({
            x: new Date(entry.createdAt).getTime(),
            y: entry[field]
          }))
          .sort((a, b) => a.x - b.x);

          const formattedData = sortedData.map(entry => ({
            x: new Date(entry.x).toLocaleTimeString(),
            y: entry.y
          }));

        setWeatherData(formattedData);
      });
  }, [field]);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "8vh", width: "180px" }}>
      <Line
        data={{


          datasets: [
            {
              label: field.charAt(0).toUpperCase() + field.slice(1), // Capitalizar el nombre del campo
              data: weatherData,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }
          ]
        }}
        options={{
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: false
            }
          },
        }}
      />
    </div>
  );
};

export default WeatherChart;