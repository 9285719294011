import React, { useState } from 'react';
import './nuevousuario.css';
import cerrar from '../Imagenes/cerrar.png'
import Swal from 'sweetalert2'
import validator from 'validator';
import ojo from '../Imagenes/ojo.png'

function Nuevousuario () {

const Close = () => {

    document.getElementById("newuser").style.display="none";

};

const Home = () => {

  document.getElementById("newuser").style.display="none";

};

const [isPasswordVisible, setIsPasswordVisible] = useState(false);

const handleClick = () => {
  setIsPasswordVisible(!isPasswordVisible);
};

const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    role: '1',
    email: '',
    password_hash: '',
    })

const handleChange = (e) => {
    const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
};
    
const handleSubmit = async (e) => {
try {
    const response = await fetch('http://mine-360.com:8080/api/auth/signup', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(formData)
    });

    if (response.ok) {
    Swal.fire({
        title: "Guardado!",
        confirmButtonText: '<a href="./">OK</a>',
        icon: "success",
    })
    } else {
    Swal.fire({
        title: "Error al enviar los datos!",
        confirmButtonText: 'Atras',
        icon: "error",
    })
    }
} catch (error) {
    console.error('Error al enviar los datos: ', error);
    Swal.fire({
    title: "Error al enviar los datos!",
    confirmButtonText: 'Atras',
    icon: "error",
    })
}
};

const enviar = (e) => {
    const passwordspecial = /[$#%&/*]/;
    e.preventDefault();
  
    if (formData.password_hash.length < 6 && formData.password_hash.length > 1) {
      Swal.fire({
        title: "La contraseña debe tener al menos 6 caracteres",
        confirmButtonText: 'OK',
        icon: "warning",
      });
    } else if (formData.username === '' || formData.first_name === '' || formData.last_name === '' || formData.email === '' || formData.password_hash === '' || formData.role === '') {
      Swal.fire({
        title: "Rellene los campos faltantes!",
        confirmButtonText: 'OK',
        icon: "warning",
      });
    } else {
      if (validator.isEmail(formData.email) === false) {
        Swal.fire({
          title: "Email Invalido",
          confirmButtonText: 'OK',
          icon: "warning",
        });
      }
      if (validator.isAlpha(formData.first_name, 'es-ES') === false || validator.isAlpha(formData.last_name, 'es-ES') === false) {
        Swal.fire({
          title: "Nombre/Apellido solo puede contener letras",
          confirmButtonText: 'OK',
          icon: "warning",
        });
      }
      if (validator.isAlpha(formData.password_hash, 'es-ES') === true || passwordspecial.test(formData.password_hash) === false) {
        Swal.fire({
          title: "La contraseña debe contener al menos 1 numero y al menos 1 caracter especial .$#%&/*",
          confirmButtonText: 'OK',
          icon: "warning",
        });
      } else {
        Swal.fire({
          title: "Quieres guardar los cambios?",
          showCancelButton: true,
          confirmButtonText: "Guardar",
          icon: "question",
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleSubmit();
          }
        });
      }
    }
  };

  return (

    <div className="ventanaformularionu">

        <div className="cerrar"><img src={cerrar} alt="salida" height="30px" width="30px" onClick={Close}></img></div>
    
        <div className="formularionu">
      
            <div className="formulario1nu">

                <div className="ordenarnu" >
                    <label>USUARIO:</label>
                    <div><input  className="estilo" type="text" maxLength="20" name="username" onChange={handleChange}></input></div>
                </div>

                <div className="ordenarnu" >
                    <label for="ayudante 1">NOMBRE:</label>
                    <input className="estilo" type="text" maxLength="20" name="first_name" onChange={handleChange}></input>
                </div>

        
                <div className="ordenarnu">
                    <label for="ayudante 2">APELLIDO:</label>
                    <input  className="estilo" type="text" maxLength="20" name="last_name" onChange={handleChange}></input>
                </div>

                <div className="ordenarnu">
                    <label for="faena_ficha">EMAIL:</label>
                    <input  className="estilo" type="email" name="email" maxLength="40" onChange={handleChange}></input>
                </div>

                <div className="ordenarnu">
                    <label for="tipo de trabajo">CONTRASEÑA:</label>
                    <div style={{display: "flex", alignItems: "center"}}> 
                    <input className="estilo" type={isPasswordVisible ? 'text' : 'password'} name="password_hash" maxLength="13" onChange={handleChange}></input> 
                    <img src={ojo} alt="Ojo" onClick={handleClick} className='ojonu'/> </div>
                </div>

                <div className="ordenarnu">
                    <label for="Role">ROL:</label>
                    <select name="role" className="estilo" onChange={handleChange}>
                        <option value="" disabled defaultValue hidden>Seleccionar</option>
                        <option value="1">Administrador</option>
                        <option value="2">Usuario</option>
                        <option value="3">Visualizador - Riego</option>
                        <option value="4">Visualizador - Cabina</option>
                        <option value="5">Visualizador - Tablets</option>
                    </select>
                </div>

            </div>

            <div className="formulario2nu">

            <button type="Submit" className="boton" onClick={Home}>Atras</button>
            <button type="button" className="boton"onClick={enviar} >Enviar </button>

            </div>
      
        </div>

    </div>
  );
}

export default Nuevousuario;