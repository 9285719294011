import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import HalfCircleSpinner from '../Componentes/loading'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LinesChart = ({ fechainicio, fechafin, equipo, tag }) => {
    const [loading, setLoading] = useState(true); // Variable de estado para controlar la carga de datos
    const [vacio, setVacio] = useState(true); // Variable de estado para controlar si el response viene en blanco
    const [data, setData] = useState({
        label: [],
        datasets: [
            {
                label: 'CO2',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(255, 114, 0, 0.8)',
                backgroundColor: 'rgba(255, 114, 0, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(255, 114, 0, 0.8)',
                borderWidth: 3
            },
            {
                label: 'VOC',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(0, 13, 255, 0.8)',
                backgroundColor: 'rgba(0, 13, 255, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(0, 13, 255, 0.8)',
                borderWidth: 3
            },
            {
                label: 'CH2O',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(86, 86, 86, 0.8)',
                backgroundColor: 'rgba(86, 86, 86, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(86, 86, 86, 0.8)',
                borderWidth: 3
            },
            {
                label: 'CO',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(255, 0, 0, 0.77)',
                backgroundColor: 'rgba(255, 0, 0, 0.77)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(255, 0, 0, 0.77)',
                borderWidth: 3
            },
            {
                label: 'O3',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(0, 227, 44, 0.77)',
                backgroundColor: 'rgba(0, 227, 44, 0.77)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(0, 227, 44, 0.77)',
                borderWidth: 3
            },
            {
                label: 'NO2',
                data: [],
                tension: 0.3,
                fill : false,
                borderColor: 'rgba(255, 0, 252, 0.77)',
                backgroundColor: 'rgba(255, 0, 252, 0.77)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(255, 0, 252, 0.77)',
                borderWidth: 3
            },
        ]
    });
    
    useEffect(() => {
        fetchData();
    }, []);
        
    const url = 'http://mine-360.com:8080/api/cabina/sensor/infografxfecha';

            const fetchData = async () => {
                try {
                    const response1 = await fetch(url, { 
                      method: "POST",
                      body:JSON.stringify({"equipo": equipo, "sensor": "data_co2", "dateinicio": fechainicio, "datefin": fechafin}),
                      headers: {"Content-Type": "application/json"}
                    })
                    const data1 = await response1.json();
    
                    const response2 = await fetch(url, { 
                        method: "POST",
                        body:JSON.stringify({"equipo": equipo, "sensor": "data_voc", "dateinicio": fechainicio, "datefin": fechafin}),
                        headers: {"Content-Type": "application/json"}
                      })
                      const data2 = await response2.json();
    
                    const response3 = await fetch(url, { 
                        method: "POST",
                        body:JSON.stringify({"equipo": equipo, "sensor": "data_ch2o", "dateinicio": fechainicio, "datefin": fechafin}),
                        headers: {"Content-Type": "application/json"}
                      })
                      const data3 = await response3.json();

                      const response4 = await fetch(url, { 
                        method: "POST",
                        body:JSON.stringify({"equipo": equipo, "sensor": "data_co", "dateinicio": fechainicio, "datefin": fechafin}),
                        headers: {"Content-Type": "application/json"}
                      })
                      const data4 = await response4.json();
      
                      const response5 = await fetch(url, { 
                          method: "POST",
                          body:JSON.stringify({"equipo": equipo, "sensor": "data_o3", "dateinicio": fechainicio, "datefin": fechafin}),
                          headers: {"Content-Type": "application/json"}
                        })
                        const data5 = await response5.json();
      
                      const response6 = await fetch(url, { 
                          method: "POST",
                          body:JSON.stringify({"equipo": equipo, "sensor": "data_no2", "dateinicio": fechainicio, "datefin": fechafin}),
                          headers: {"Content-Type": "application/json"}
                        })
                        const data6 = await response6.json();

                    // Comprobar si los arrays de datos están vacíos
                    if (data1.length === 0 || data2.length === 0 || data3.length === 0) {
                    setVacio(true); // Establecer vacio en true si algún array está vacío
                    } else {
                    setVacio(false); // Establecer vacio en false si todos los arrays tienen datos
            
                    setData(prevData => ({
                        labels: data1.map(item => {

                    // Suponiendo que recibes la fecha en formato 'MM/DD HH:MM'
                    const fechaOriginal = item.hora_medida;

                    // Convertir la fecha original a un objeto de tipo Date
                    const fecha = new Date(`2024/${fechaOriginal}`);

                    // Restar 3 o 4  horas a la fecha por verano u invierno 
                    fecha.setHours(fecha.getHours() - 4);

                    // Obtener la nueva fecha en formato 'MM/DD HH:MM'
                    const nuevoLabel = `${fecha.getMonth() + 1}/${fecha.getDate()} ${fecha.getHours()}:${('0' + fecha.getMinutes()).slice(-2)}`;
                        
                        return nuevoLabel;
                          }),
                        datasets: [
                            { ...prevData.datasets[0], data: data1.map(item => item.data_co2) },
                            { ...prevData.datasets[1], data: data2.map(item => item.data_voc) },
                            { ...prevData.datasets[2], data: data3.map(item => item.data_ch2o) },
                            { ...prevData.datasets[3], data: data4.map(item => item.data_co) },
                            { ...prevData.datasets[4], data: data5.map(item => item.data_o3) },
                            { ...prevData.datasets[5], data: data6.map(item => item.data_no2) },
                        ],
                    }));
        
                }

                setLoading(false); 

                  } catch (error) {
                    console.error(error);
                  }
        
                // Una vez obtenidos los nuevos datos, actualiza el estado setData con los nuevos valores
            };
    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            y: {
                min: -0.5,
                ticks: { color: 'rgb(0, 0, 0)' },
                grid: { color: 'rgb(0, 0, 0, 0.1)' }
            },
            x: {
                ticks: {
                    color: 'rgb(0, 0, 0)',
                    autoSkip: true,
                    maxRotation: 35,
                    minRotation: 35
                },
                grid: { color: 'rgb(0, 0, 0, 0.1)' }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: "black",
                },
                title: {
                    display: true,
                    text: 'GASES ' + tag,
                    color: "black",
                    font: {
                        size: 16,
                        weight: "bold",
                    }
                },
            }
        },
    };

    return (
        <>
        {loading && <HalfCircleSpinner/>}
        {!loading && !vacio &&<Line data={data} options={options}/>}
        {!loading && vacio &&<div style={{ fontWeight: "bold", color: "black"}}>No se encontraron datos en el equipo y rango de fechas seleccionado.</div>}
        </>
    );
    
};

export default LinesChart;